<template>
  <div class="newsDetail">
      <div class="ql-editor" v-html="content"></div>

  </div>
</template>

<script>
export default {
  name: "newsDetail",
  data(){
    return {
    content:'',
    }
  },
  created() {
    this.getDetail();
  },
  methods:{
    getDetail(){
      this.$get( '/website/news/' + this.$route.query.id).then((res)=>{
          this.content=res.data.context;
      }).catch((err)=>{
        this.msgError(err.msg);
      })
    }
  }
}
</script>

<style scoped lang="scss">
.newsDetail{
  padding-top: 80px;
  width: 1200px;
  margin: 0 auto;
}
</style>
